// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_appsync_apiKey": "da2-2fa4nov3pfeevj5ftztytl5ily",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_graphqlEndpoint": "https://yueh2ummzjf4tomkzuribm7a2q.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_user_files_s3_bucket": "jnp-main-frontend-s3nestedstacks3-jnpmain6f872843-1x4z6f221lzs0",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:a68aec78-17b1-4b90-a63f-6e3b3b67ee59",
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": [],
        "passwordPolicyMinLength": 8
    },
    "oauth": {
        "redirectSignOut": "http://localhost:3000/,https://main.d17ja8lab0jwko.amplifyapp.com/,https://main.part.imagesimilarity.tecalliance.net/,https://part.imagesimilarity.tecalliance.net/",
        "domain": "jnp-auth-main.auth.eu-central-1.amazoncognito.com",
        "responseType": "code",
        "redirectSignIn": "http://localhost:3000/,https://main.d17ja8lab0jwko.amplifyapp.com/,https://main.part.imagesimilarity.tecalliance.net/,https://part.imagesimilarity.tecalliance.net/",
        "scope": [
            "openid",
            "profile",
            "email"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_user_pools_id": "eu-central-1_MmJdqgFLY",
    "aws_user_pools_web_client_id": "66a4cqhvm4vbvml85auhbs3v3a",
    "aws_cognito_region": "eu-central-1",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_custom_provider": "JNPmainFrontendCoAzureAD1E0A663B",
    "aws_cognito_social_providers": [],
    "backend": {
        "region": "eu-central-1"
    },
    "amplify_appid": "d17ja8lab0jwko",
    "aws_project_region": "eu-central-1"
};

export default awsmobile;
